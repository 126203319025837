import { AnnouncementBanner, AnnouncementLevel } from '@components/Banner';
import Links from '@configuration/HeaderNavLinks.json';
import { ReactComponent as Logo } from '@content/ElevateLogo.svg';
import { CSSProperties } from 'react';

interface HeaderLink {
	label: string;
	href: string;
	target?: string;
}

interface HeaderProps {
	title: string;
	content?: Array<HeaderLink>;
	href?: string;
	target?: string;
}

const NavigationLinks: Array<HeaderProps> = Links;

export default () => {
	const sectionStyle: CSSProperties = {
		flex: '1',
		display: 'flex',
		justifyContent: 'left',
		marginRight: '10px',
	};

	return (
		<>
			<header
				style={{
					zIndex: '30',
					width: '100%',
					position: 'fixed',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					height: '88px',
					color: 'white',
					backgroundColor: 'white',
					borderBottomColor: '#1C092D',
					borderBottomWidth: '3px',
					borderBottomStyle: 'solid',
				}}>
				<div
					style={{
						...sectionStyle,
						alignItems: 'center',
						textAlign: 'left',
						justifyContent: 'flex-start',
						paddingLeft: '27px',
					}}>
					<a href='/'>
						<Logo height='30px' color='#1c092d' />
					</a>
				</div>
			</header>
			<AnnouncementBanner msg={undefined} level={AnnouncementLevel.NOTICE} />
		</>
	);
};
