import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Index from '@pages/index';
import Signin from '@pages/signin';

const router = createBrowserRouter([{
  path: '/',
  children: [
  {
    "path": "/",
    "element": <Index Location='Home' Description={'Home page of the website.'} />,
    "index": true
  },
  {
    "path": "/signin",
    "element": <Signin Location='Sign In' Description={'Sign into myElevate.'} />,
    "index": false
  }
]
}]);

export default () => <RouterProvider router={router} />