import { Gradient } from '@components/Gradient';
import RouteTags from '@components/Meta/RouteTags';
import Modal from '@components/Modal';
import * as jaElevateLogo from '@content/ElevateLogo.svg';
import * as jaLogo from '@content/jaDevelopmentLogoSVG.svg';
import { useEffect, useLayoutEffect, useState } from 'react';
import { FaDiscord } from 'react-icons/fa';
import { SiRoblox } from 'react-icons/si';
import { useCookie } from 'react-use';

const Links: Array<{ label: string; href: string }> = [
	{
		href: '/',
		label: 'Terms',
	},
	{
		href: '/',
		label: 'Contact',
	},
	{
		href: '/',
		label: 'Servers',
	},
];

export default (PageConfig: { Location: string; Description?: string }) => {
	const [showModal, setShowModal] = useState<boolean>(false);
	const [cookies, setCookie] = useCookie('.myElevate-CookiesAgreed');

	useEffect(() => {
		if (!cookies || cookies === null || cookies === '' || cookies === 'false' || !(cookies === 'true'))
			setShowModal(true);
	}, []);

	useLayoutEffect(() => {
		const gradient = new Gradient();
		//@ts-ignore
		gradient.initGradient('#gradient-canvas');
	}, []);

	return (
		<div>
			<RouteTags Location={PageConfig.Location} Description={PageConfig.Description} />
			<canvas id='gradient-canvas' data-transition-in='true' width={1920} height={600} />
			<Modal
				isOpen={showModal}
				onClose={() => setShowModal(false)}
				dismissible
				style={{
					backdropFilter: 'blur(5px)',
					backgroundColor: 'rgba(27, 27, 29,.7)',
					borderRadius: '.6rem',
					width: '600px',
					height: 'auto',
					boxShadow: 'var(--tw-ring-offset-shadow,0 0 transparent),var(--tw-ring-shadow,0 0 transparent),var(--tw-shadow)',
					padding: '40px 80px',
				}}>
				<div
					style={{
						width: '100%',
						height: '5px',
						backgroundColor: 'rgba(28, 9, 45, 0.9)',
						borderRadius: '9999px',
						marginTop: '0',
					}}></div>

				<h1
					style={{
						fontSize: '30px',
						textAlign: 'left',
						marginTop: '25px',
						marginBottom: '5px',
					}}>
					A word, please?
				</h1>
				<p style={{ fontFamily: 'SegoeUI' }}>
					myElevate needs to use cookies in your browser, but we can't do this without
					permission. You can read our full cookie policy{' '}
					<a
						href='https://jadevelopment.co.uk/pages/cookie-policy'
						style={{ color: 'currentcolor', textDecoration: 'underline' }}>
						here
					</a>{' '}
					to fully understand what we do with cookies in your browser. Alternatively, you
					can read our quick overview of how myElevate specifically utilises cookies in
					your browser. <br /> <br /> myElevate utilises cookies to provide you with an
					easy-to-use experience of saving your login per session. This allows you to come
					back at any time to myElevate without having to log in every time you need to
					access our panel.
				</p>
				<div style={{ display: 'flex', gap: '10px' }}>
					<button
						style={{ flex: '1' }}
						onClick={() =>
							(window.location.href =
								'https://jadevelopment.co.uk/pages/cookie-policy')
						}>
						Learn More
					</button>
					<button
						style={{ flex: '1' }}
						onClick={() => {
							setShowModal(false);
							setCookie('true', {
								secure: true,
								domain:
									process.env.NODE_ENV === 'development' ||
									process.env.NODE_ENV === 'test'
										? 'localhost'
										: 'elevate.jadevelopment.co.uk',
								sameSite: 'strict',
							});
						}}>
						OK
					</button>
				</div>
			</Modal>
			<div style={{ height: '100vh' }}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						textAlign: 'left',
						height: 'auto',
						minHeight: '100vh',
					}}>
					<div
						style={{
							backdropFilter: 'blur(5px)',
							backgroundColor: 'rgba(27, 27, 29, .8)',
							borderRadius: '.6rem',
							width: '500px',
							height: 'auto',
							color: '#e3e3e3',
							padding: '30px 30px',
						}}>
						<div
							style={{
								width: '100%',
								height: '5px',
								backgroundColor: 'rgba(28, 9, 45, 0.9)',
								borderRadius: '9999px',
								marginTop: '5px',
							}}></div>

						<div
							style={{
								width: '50%',
								height: '40px',
								display: 'flex',
								marginTop: '30px',
								gap: '20px',
							}}>
							<div
								style={{
									width: 'auto',
								}}>
								<jaLogo.ReactComponent
									fill='#e3e3e3'
									style={{
										height: '100%',
										color: '#e3e3e3',
									}}
								/>
							</div>
							<div
								style={{
									width: '5px',
									height: '100%',
									backgroundColor: '#e3e3e3',
									borderRadius: '9999px',
								}}></div>
							<div style={{ width: 'auto' }}>
								<jaElevateLogo.ReactComponent
									style={{
										height: '100%',
										width: '100%',
										color: '#e3e3e3',
									}}
								/>
							</div>
						</div>
						<h1
							style={{
								fontSize: '30px',
								textAlign: 'left',
								marginTop: '25px',
								marginBottom: '5px',
							}}>
							Welcome Back.
						</h1>
						<p style={{ fontSize: '16px', opacity: '0.6', marginTop: '0' }}>
							Sign into myElevate
						</p>
						<br />
						<button
							style={{
								width: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
							onClick={() =>
								(location.href = `https://discord.com/oauth2/authorize?client_id=1124056390449758388&response_type=code&redirect_uri=${location.origin}%2Fsignin&scope=identify+guilds+openid+applications.commands.permissions.update`)
							}>
							<FaDiscord
								style={{
									width: '30px',
									height: '30px',
									marginRight: '10px',
									verticalAlign: 'middle',
								}}
							/>
							Sign in with Discord
						</button>
						<button
							style={{
								marginTop: '10px',
								marginBottom: '30px',
								width: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
							onClick={() =>
								(location.href = `https://discord.com/oauth2/authorize?client_id=1124056390449758388&response_type=code&redirect_uri=${location.origin}%2Fsignin&scope=identify+guilds+openid+applications.commands.permissions.update`)
							}>
							<SiRoblox
								style={{
									width: '30px',
									height: '30px',
									marginRight: '10px',
									verticalAlign: 'middle',
								}}
							/>
							Sign in with Roblox
						</button>
						<p style={{ margin: '0', marginBottom: '7px' }}>
							Don't have an account?{' '}
							<a
								href='/signup'
								style={{
									color: '#e3e3e3',
									textDecoration: 'underline',
								}}>
								Make one!
							</a>
						</p>
					</div>
					<div
						style={{
							marginTop: '20px',
							display: 'flex',
							color: '#e3e3e3',
							width: '500px',
						}}>
						<div
							style={{
								justifyContent: 'flex-start',
								textAlign: 'left',
								flex: '1.4',
								alignItems: 'left',
							}}>
							<a href='' style={{ color: '#e3e3e3' }}>
								© jaDevelopment 2024
							</a>
						</div>
						<div
							style={{
								justifyContent: 'flex-end',
								textAlign: 'right',
								flex: '1',
								alignItems: 'right',
								display: 'flex',
								fontSize: '15px',
								gap: '1px',
							}}>
							{Links.map((Link) => (
								<a
									href={Link.href}
									style={{
										color: '#e3e3e3',
										flex: '1',
									}}>
									{Link.label}
								</a>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
