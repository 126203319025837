import { Helmet } from 'react-helmet';

interface PageConfig {
	Location: string;
	Description?: string;
}

export default (RouteConfig: PageConfig) => {
	let RouteDesc = `Elevate is a powerful MIS system designed to revolutionise school management through integration and intelligent functionality. Elevate combines user experience with cutting-edge features, providing staff and students the ability to manage information seamlessly.`;
	if (RouteConfig.Description) RouteDesc = RouteConfig.Description;

	return (
		<Helmet>
			<title>{`${RouteConfig.Location} - jaElevate`}</title>
			<meta charSet='utf-8' />
			<meta itemProp='description' content={RouteDesc} />
			<meta itemProp='og:description' content={RouteDesc} />
			<meta property='og:title' content={`${RouteConfig.Location} - jaElevate`} />
			<meta name='apple-mobile-web-app-title' content={`${RouteConfig.Location} - jaElevate`} />
			<meta name='twitter:title' content={`${RouteConfig.Location} - jaElevate`} />
		</Helmet>
	);
};
