import { Gradient } from '@components/Gradient';
import Header from '@components/Headers/Minimal';
import RouteTags from '@components/Meta/RouteTags';
import Home from '@components/Production/Home';
import { ReactComponent as JADevelopmentLogo } from '@content/Asset 1-JA.svg';
import { ReactComponent as Logo } from '@content/ElevateLogo.svg';

import { useLayoutEffect } from 'react';

export default (PageConfig: { Location: string; Description?: string }) => {
	useLayoutEffect(() => {
		const gradient = new Gradient();
		//@ts-ignore
		gradient.initGradient('#gradient-canvas');
	}, []);

	if (process.env.NODE_ENV === 'development')
		return <Home Location={PageConfig.Location} Description={PageConfig.Description} />;

	return (
		<div>
			<RouteTags Location={PageConfig.Location} Description={PageConfig.Description} />
			<Header />
			<canvas id='gradient-canvas' data-transition-in='true' width={1920} height={600} />

			<div
				style={{
					height: '100vh',
					justifyContent: 'center',
					justifyItems: 'center',
					alignItems: 'center',
					alignContent: 'center',
					textAlign: 'center',
					paddingLeft: '25%',
					paddingRight: '25%',
				}}>
				<a href='/'>
					<Logo height='70px' color='#e3e3e3' />
				</a>
				<h1>Bring everything you need into one place.</h1>
				<p style={{ fontSize: '18px', fontWeight: '600' }}>
					<br />A powerful MIS system designed to revolutionise school management through
					integration and intelligent functionality. Elevate combines user experience with
					cutting-edge features, providing staff and students the ability to manage
					information seamlessly.
				</p>
				<br />
				<br />
				<b>Elevate by jaDevelopment, coming soon.</b>
				<br />
				<br />
				<a href='/'>
					<JADevelopmentLogo height='50px' fill='#e3e3e3' />
				</a>
			</div>
		</div>
	);
};
